const dev = {
    ossconfig: { accessKeyId: '', accessKeySecret: '', bucket: '', region: '' },
    host: 'https://apis.mbuymart.com/api_besiter/',
    wss: 'ws://106.14.173.97:8091/api_besiter/',
    loginhost: 'https://apis.mbuymart.com/',
    aliurl: '',
    ossurl: '/api_besiter/uploads',
    producename: 'PowerOn v2.0',
    pathname: 'admindev',
    env: 'dev'
}

export default dev // 开发环境
